@for (option of props.options | formlySelectOptions: field | async; track option; let i = $index) {
	<div class="form-check custom-radio" [class.form-check-inline]="props.formCheck === 'inline'">
		<input
			type="radio"
			[id]="id + '_' + i"
			class="form-check-input"
			[name]="field.name || id"
			[class.is-invalid]="showError"
			[value]="option.value"
			[formControl]="formControl"
			[formlyAttributes]="field"
			[attr.disabled]="option.disabled || formControl.disabled ? true : null" />
		<label
			class="form-check-label"
			[for]="id + '_' + i"
			[ngClass]="{ disabled: option.disabled }"
			[attr.aria-label]="props.options?.[i]?.ariaLabel ?? null">
			{{ option.label }}
		</label>
	</div>
}
